/**
 * @kind ko binding handler
 * @name percentage
 * @description A ko binding handler that add a percentage sign to the element its applied to
 **/
ko.bindingHandlers.percentage = {
	update: (element, valueAccessor, allBindingsAccessor) => {
		return ko.bindingHandlers.text.update(element, () => {
			const value = +(ko.utils.unwrapObservable(valueAccessor()) || 0);

			return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + '%';
		});
	}
};

export default 'bindings';