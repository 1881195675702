import template from './account_component.html';
import AccountModel from '../../lib/models/account_model'

/**
 * @kind component
 * @class AccountViewModel
 * @description This is account ko component for the display and modification of account data
 */
class AccountViewModel
{
	constructor(params)
	{
		this.params = params;
		this.accountModel = ko.observable(new AccountModel(params));
		this.displayContext = ko.observable(params.displayContext || 'page');
		this.data = ko_helper.safe_observable(params.data);
	}

	async saveAccount()
	{
		if (!this.accountModel().values()?.account_uuid())
		{
			let confirm = await Grape.alerts.confirm({ type:'warning', title:'Create New Account', message: 'Create new Account?' });
			if (!confirm)
				return
		} else {
			let confirm = await Grape.alerts.confirm({ type:'warning', title:'Save Account', message: 'Save Changes?' });
			if (!confirm)
				return
		}

		let res = await this.accountModel().save();
		if (res.status === 'OK')
		{
			Grape.alerts.alert({ type:'success', title:'Account Saved', message:'Successfully saved account.' });
			this.accountModel().load(res.account_uuid);
		} 
		else
			Grape.alerts.alert( {type:'danger', title:'Account Save Error', message:res.message });
	}

	async clear()
	{
		let confirm = await Grape.alerts.confirm({ type:'warning', title:'Clear', message: 'Clear and lose any changes?' });
		if (!confirm)
			return

		this.accountModel().clear();
	}

    async addContact()
    {
        this.accountModel().viewContact();
    }
}

export default {
	name: 'account-component',
	viewModel: AccountViewModel,
	module_type: 'ko',
	template: template
};