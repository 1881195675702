
/**
 * @kind ko binding handler
 * @name percentageInput
 * @description converts number to percentage input field
 **/
ko.bindingHandlers.percentageInput = {
	init: (element, valueAccessor) => {

		let cursorPosition = 0
		ko.utils.registerEventHandler(element, "keyup", function() {
			cursorPosition = element.selectionStart;
			let newValue = parseFloat(Number(element.value.replace(/[^0-9.-]/g, '')).toFixed(2));
			valueAccessor()(newValue);

			element.value = Number(newValue).toFixed(2).replace(/[^0-9.-]/g, '').replace(/\d(?=(\d{3})+\.)/g, '$&,')+'%';
			element.selectionStart = cursorPosition;
			element.selectionEnd = cursorPosition;
		});
		element.value = Number(ko.unwrap(valueAccessor())).toFixed(2).replace(/[^0-9.-]/g, '').replace(/\d(?=(\d{3})+\.)/g, '$&,')+'%';
	},
	update: (element, valueAccessor) => {
		let newValue = parseFloat(Number(ko.unwrap(valueAccessor())).toFixed(2));
		valueAccessor()(newValue);
		element.value = Number(ko.unwrap(valueAccessor())).toFixed(2).replace(/[^0-9.-]/g, '').replace(/\d(?=(\d{3})+\.)/g, '$&,')+'%';
	}
};

export default 'bindings';