
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';
import appcaches from './caches/index.js';
import plugins from './plugins/index.js';
import ko_bindings from './lib/ko_bindings/index.js';

import package_json from '../package.json';
import './css/ui-ps-accounts-receivable.css';

(function() {

	window.Grape.modules.push(package_json);

	Grape.cache.registerCaches(appcaches);
	Grape.component.registerComponents(components);
	Grape.dialog.registerDialogs(dialogs);
	Grape.pages.registerPages(pages);
	Grape.plugins.registerPlugins(plugins);

})();

