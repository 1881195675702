
import template from './invoice-edit.html';
import Models from './Models.js';

/**
 * Invoice Page view model
 */
class InvoiceEditPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;

		this.invoice = ko.observable();
		window.invoice = this.invoice; //TODO remove

		this.create_new_account = ko.observable(false);
		this.selected_new_owner = ko.observable();
		this.selected_account = ko.observable();
		this.selected_seller = ko.observable();
		this.selected_contact = ko.observable();
		this.selected_new_owner.subscribe((nv) => this.new_owner_updated(nv));
		this.selected_account.subscribe((a)=>this.account_updated(a));
		this.selected_seller.subscribe((nv) => this.seller_updated(nv));
		this.selected_contact.subscribe((nv) => this.contact_updated(nv));
		this.create_new_account.subscribe((createNewAccount) => {
			if (createNewAccount)
			{
				this.selected_new_owner({ text: this.selected_account()?.name });
				this.selected_account({});
				let newInvoice = new Models.Document({ document_type:'Invoice' });
				newInvoice.account().seller(this.invoice().account().seller());
				this.invoice(newInvoice);
			} 
			else
				this.selected_account(this.invoice().account().serialize());
		});

		this.can_save = ko.computed(() => {
			if (!this.invoice()?.account()?.account_uuid() && !this.create_new_account())
				return false;

			return true;
		})

		this.edit_mode = ko.observable(true);
		this.change_rows_visible = ko.observable(false);

		this.new_line = ko.observable(new Models.DocumentLine());
	}

	/**
	 * Load invoice from server
	 */
	async load_from_id (document_id)
	{
		const invoice = new Models.Document(document_id);
		await invoice.load_from_id(document_id);
		this.invoice(invoice);
		this.selected_account(this.invoice().account().serialize());
	}

	/**
	 * Create new invoice
	 */
	async load_new ()
	{
		const invoice = new Models.Document({ document_type:'Invoice' });
		this.invoice(invoice);
	}

	async btn_save_click ()
	{
		await this.invoice().save();
		this.create_new_account(false);
	}

	async btn_new_item_click ()
	{
		let s = this.new_line().serialize();
		let nl = new Models.DocumentLine();
		nl.set(s);
		this.invoice().append_line(nl);
		this.new_line(new Models.DocumentLine());

		await this.invoice().save();
	}

	async btn_remove_item_click (line_item)
	{
		this.invoice().remove_line(line_item)
		await this.invoice().save();
	}

	async btn_remove_change_click (change_row, line_item)
	{
		line_item.price_change_rows.pop(change_row);
		await this.invoice().save();
	}

	async btn_add_change_click (change_row, line_item)
	{
		line_item.addPriceChangeRow(change_row);
		await this.invoice().save();
	}

	async btn_add_inv_change_click ()
	{
		this.invoice().addPriceChangeRow(new Models.PriceChangeRow(this.invoice().new_change().serialize()));
		this.change_rows_visible(false);
		await this.invoice().save();
	}

	async btn_remove_inv_change_click (index)
	{
		this.invoice().price_change_rows.splice(index, 1);
		// this.invoice().price_change_rows.valueHasMutated();
	}

	async btn_show_note_click(lineItem)
	{
		lineItem.note_visible(true);
	}

	async btn_show_inv_change_click ()
	{
		this.change_rows_visible(!this.change_rows_visible());
	}

	async new_owner_updated(contact)
	{
		this.invoice().account().owner(new Models.Contact(contact));
	}

	async account_updated(account)
	{
		let accountInstance = new Models.Account(account);
		// always keep seller
		accountInstance.seller(this.invoice().account().seller());
		this.invoice().account(accountInstance);
	}

	async seller_updated (seller)
	{
		if (seller)
			this.invoice().account().seller(new Models.Seller(seller));
	}

	contact_updated (contact)
	{
		if (contact)
			this.invoice().contact(new Models.Contact(contact));
	}

	async btn_generate_pdf_click()
	{
		await this.invoice().invoice_pdf().download(this.invoice().document_id());
	}

	btn_changes_click (line)
	{
		line.changes_visible(!line.changes_visible())
	}

	btn_show_invoice_params()
	{
		this.invoice().showSendParams(true);
	}

	btn_close_click()
	{
		this.invoice().showSendParams(false);
	}

	async btn_send_invoice_click()
	{
		await this.invoice().send();
	}

	adjustTextareaRows (note)
	{
		if (!note()) return 1;

		return note().split('\n').length || 1;
	}

	async upload_csv (event)
	{
		let has_error = false;

		let file = event.target.files[0];

		if (!file)
			return;

		let reader = new FileReader();
		reader.onload = async (e) =>
		{
			try
			{
				let content = e.target.result;

				//Skip the header row
				let rows = content.split('\n').slice(1);

				for (let i = 0; i < rows.length; i++)
				{
					let row = rows[i];
					if (row.trim() === "")
						continue;

					let cells = row.split(',');
					//Extract values from the cells
					let [description, qty, ppu] = cells;

					let result = qty.match(/-?\d+/);

					if (result)
						qty = result[0];
					else
						throw new Error("No numeric value found in the string");

					//Check for missing fields
					if (!description || !qty || !ppu)
						throw new Error(`Row ${i+2}: Please fill in all the fields.`);

					let max_idx = 0;
					this.invoice().lines().map((x) => max_idx = max_idx < x.idx() ? x.idx() : max_idx);
					if (!max_idx)
						max_idx = 0;

					let nl = new Models.DocumentLine();
					nl.set({ idx: max_idx+1, description: description, qty: qty, ppu: ppu, total_value: qty*ppu });
					this.invoice().lines.push(nl)
				}
			} catch (error) {
				Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
				console.error(error);
				has_error = true;
			}

			if (!has_error)
				Grape.alerts.alert({title: 'Success', type: 'success', message: 'Upload complete!'});
		};

		reader.onerror = (err) => {
			console.error(err.message || 'Failed to read the CSV file.');
			Grape.alerts.alert({ title: 'Error', type: 'error', message: err.message || 'Failed to read the CSV file.' });
		};

		reader.readAsText(file);
	}

	async productLookup (name, component)
	{
		let res = await Grape.tables.select({
			schema:'products',
			table:'v_products',
			filter:[{field:"name", operator:"ILIKE", value:`%${name}%`}],
			limit: 100
		});
		res ??= [];
		return res.records.map((item)=>{
			return {
				description:item?.name,
				ppu:item?.fields?.price,
				image:item?.fields?.image
			}
		});
	};

	async accountLookup (name, component, extraParams)
	{
		name??='';
		let params = [];

		params.push(`searchtext=${encodeURIComponent(name)}`);
		if (extraParams.includes('seller_uuid'))
			params.push(`seller_uuid=${encodeURIComponent(this.invoice().account().seller().seller_uuid())}`);

		let res = await Grape.fetches.fetchJSON(`/api/accounts/search/?${params.join('&')}`);
		res.accounts ??= [];
		return res.accounts;
	};

	accountOptionsFormatter ({ name, data, component })
	{
		return `<b>Owner:</b> ${data?.owner?.personal_detail?.name} <b>| Acc:</b> ${data?.name} (${data.refnr})`
	}

	async contactLookup (name='', component, extraParams=[])
	{
		// name??='';
		let params = [];

		params.push(`searchtext=${encodeURIComponent(name.trim().replace(/[+.()@&:*]/g, ' ').replace(/\s\s+/g, ' ').trim().split(' ').join(':* & ')+':*')}`);

		if (extraParams.includes('seller_uuid'))
			params.push(`seller_uuid=${encodeURIComponent(this.invoice().account().seller().seller_uuid())}`);
		if (extraParams.includes('account_uuid'))
			params.push(`account_uuid=${encodeURIComponent(this.invoice().account().account_uuid())}`);
		if (extraParams.includes('owners'))
			params.push(`owners=true`);
		else
			params.push(`owners=false`);

		let res = await Grape.fetches.fetchJSON(`/api/contacts/search/?${params.join('&')}`);
		res.contacts ??= [];
		return res.contacts.map((item)=>{
			item.text = item?.personal_detail?.name||(item?.personal_detail?.firstname+' '+item?.personal_detail?.surname);
			return item;
		});
	};
}

/**
 * Invoice page class
 */
class InvoiceEditPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new InvoiceEditPageViewModel(this);
		this.name = 'InvoiceEditPageClass';
	}

	async init()
	{
		if (this.bindings.document_id)
			this.viewModel.load_from_id(this.bindings.document_id);
		else
			this.viewModel.load_new();
	}

	async updateData() {
		await this.viewModel.update();
	}
}

export default {
	route: '[/]ui/invoice-edit',
	page_class: InvoiceEditPageClass,
	template: template
}