class AccountModel {
	constructor(params){
		this.account = params.account;
        this.contacts = ko.observableArray();
		this.values = ko.observable();
		this.clear = ko.observable(ko.utils.unwrapObservable(params.clear));
		this.fields_info = ko.observableArray();

		this.account.subscribe(()=>{
			this.createValuesTemplate();
		});

		this.fields_info.subscribe(()=>{
			this.createValuesTemplate();
		});

		this.getFieldsInfo().then((fields_info)=>{
			this.fields_info(fields_info);
		});
	}

	clear(){
		this.clear(true);
		this.account({ seller:{name: ''},owner:{personal_detail:{name: ''}}});
	}

	async load(account_uuid){
		let account = await Grape.fetches.getJSON(`api/accounts/?account_uuid=${encodeURI(account_uuid)}`);
	}

	async save(){
		let payload = ko.mapping.toJS(this.values);
		let res = await Grape.fetches.postJSON('api/accounts', payload);
		return res;
	}

	async getFieldsInfo(){
		return await Grape.cache.get('AccountFieldsInfo');
	}

    async viewContact(data){
        let contact = {};
        if (typeof data != 'undefined')
            contact = {contact: data.contacts};
        else
            contact = {contact: ''};

        let response = await Grape.dialog.open('ContactEdit', contact);
    }

	createValuesTemplate(){
		let account = ko.utils.unwrapObservable(this.account);
		let fields_info = this.fields_info();
		let values = {account_uuid:'', contact_uuid:'', contact_type_lookup_id:''};
		for (let category of fields_info){
			let fields = {}
			for (let field of category.fields){
				fields[field.field_name] = account?.[category]?.[field.field_name]||'';
			}
			values[category.category] = fields;
		}
		this.values(ko.mapping.fromJS(Object.assign(values, account||{})));
        this.contacts(ko.utils.unwrapObservable(this.values().contacts));
	}
}

export default AccountModel;