import InvoiceList from './invoice-list/invoice-list.js';
import InvoiceEdit from './invoice-edit/invoice-edit.js';

import AccountsPage from './accounts/list/list.js';
import AccountDetailPage from './accounts/detail/detail.js';

export default [
    InvoiceList,
    InvoiceEdit,

    AccountsPage,
    AccountDetailPage
];
