class Seller
{
	constructor(obj)
	{
		this.seller_id = ko.observable()
		this.seller_uuid = ko.observable()
		this.name = ko.observable();
		this.website = ko.observable();
		this.contact_nr = ko.observable();
		this.email = ko.observable();
		this.address = ko.observable();
		this.company_reg = ko.observable();
		this.company_vat = ko.observable();
		this.banking_details = ko.observable();
		this.letterhead = ko.observable();
		this.default_invoice_template = ko.observable();
		this.default_invoice_message_uuid = ko.observable();

		if (obj)
			this.set(obj);
	}

	serialize()
	{
		return {
			seller_uuid: this.seller_uuid(),
			name: this.name(),
			fields: {
				address: this.address(),
				website: this.website(),
				contact_nr: this.contact_nr(),
				email: this.email(),
				company_reg: this.company_reg(),
				company_vat: this.company_vat(),
				banking_details: this.banking_details(),
				letterhead: this.letterhead(),
				default_invoice_template: this.default_invoice_template()
			}
		};
	}

	async load()
	{
		const result = await Grape.fetches.getJSON('/api/seller', { name: this.name() });
		await this.set(result.fields);
		return result;
	}

	async set(obj)
	{
		if (!obj)
			return;

		if (obj.hasOwnProperty('name'))
			this.name (obj.name);
		if (obj.hasOwnProperty('seller_uuid'))
			this.seller_uuid (obj.seller_uuid);
		if (obj.hasOwnProperty('fields')){
			if (obj.fields.hasOwnProperty('address'))
				this.address (obj.fields.address);
			if (obj.fields.hasOwnProperty('website'))
				this.website (obj.fields.website);
			if (obj.fields.hasOwnProperty('contact_nr'))
				this.contact_nr (obj.fields.contact_nr);
			if (obj.fields.hasOwnProperty('email'))
				this.email (obj.fields.email);
			if (obj.fields.hasOwnProperty('company_reg'))
				this.company_reg (obj.fields.company_reg);
			if (obj.fields.hasOwnProperty('company_vat'))
				this.company_vat (obj.fields.company_vat);
			if (obj.fields.hasOwnProperty('banking_details'))
				this.banking_details (obj.fields.banking_details);
			if (obj.fields.hasOwnProperty('letterhead'))
				this.letterhead (obj.fields.letterhead);
			if (obj.fields.hasOwnProperty('default_invoice_template'))
				this.default_invoice_template (obj.fields.default_invoice_template);
		}
	}

	async save()
	{
		const result = await Grape.fetches.postJSON('/api/sellers', this.serialize());

		return result;
	}
}

class Contact
{
	constructor(obj)
	{
		this.contact_uuid = ko.observable();
		this.refnr = ko.observable();

		this.personal_detail = {
			name: ko.observable(),
			firstname: ko.observable(),
			surname: ko.observable(),
			dob: ko.observable()
		};

		this.contact_detail = {
			email: ko.observable(),
			phone: ko.observable(),
			contact: ko.observable(),
			vat_number: ko.observable()
		};

		this.billing_detail = {
			name: ko.observable(),
			address: ko.observable(),
			contact: ko.observable(),
			vat_number: ko.observable()
		};

		if (obj)
			this.set(obj);
	}

	serialize()
	{
		return {
			contact_uuid: this.contact_uuid(),
			refnr: this.refnr(),
			personal_detail: ko.mapping.toJS(this.personal_detail),
			contact_detail: ko.mapping.toJS(this.contact_detail),
			billing_detail: ko.mapping.toJS(this.billing_detail)
		};
	}

	set (obj)
	{
		if (obj.hasOwnProperty('refnr') && obj.refnr)
			this.refnr(obj.refnr);
		if (obj.hasOwnProperty('contact_uuid') && obj.contact_uuid)
			this.refnr(obj.contact_uuid);
		if (obj.hasOwnProperty('personal_detail') && obj.personal_detail)
		{
			if (obj.personal_detail.name)
				this.personal_detail.name(obj.personal_detail.name);
			if (obj.personal_detail.firstname)
				this.personal_detail.firstname(obj.personal_detail.firstname);
			if (obj.personal_detail.surname)
				this.personal_detail.surname(obj.personal_detail.surname);
			if (obj.personal_detail.dob)
				this.personal_detail.dob(obj.personal_detail.dob);
		}
		if (obj.hasOwnProperty('contact_detail') && obj.contact_detail)
		{
			if (obj.contact_detail.email)
				this.contact_detail.email(obj.contact_detail.email);
			if (obj.contact_detail.phone)
				this.contact_detail.phone(obj.contact_detail.phone);
			if (obj.contact_detail.contact)
				this.contact_detail.contact(obj.contact_detail.contact);
			if (obj.contact_detail.vat_number)
				this.contact_detail.vat_number(obj.contact_detail.vat_number);
		}
		if (obj.hasOwnProperty('billing_detail') && obj.billing_detail)
		{
			if (obj.billing_detail.name)
				this.billing_detail.name(obj.billing_detail.name);
			if (obj.billing_detail.address)
				this.billing_detail.address(obj.billing_detail.address);
			if (obj.billing_detail.contact)
				this.billing_detail.contact(obj.billing_detail.contact);
			if (obj.billing_detail.vat_number)
				this.billing_detail.vat_number(obj.billing_detail.vat_number);
		}
	}
}

class Account
{
	constructor(obj)
	{
		this.account_uuid = ko.observable();
		this.name = ko.observable();
		this.refnr = ko.observable();
		this.billing_email = ko.observable();
		this.billing_address = ko.observable();
		this.shipping_address = ko.observable();
		this.date_inserted = ko.observable();
		this.owner = ko.observable(new Contact());
		this.seller = ko.observable(new Seller());

		if (obj)
			this.set(obj);
	}

	serialize()
	{
		return {
			account_uuid: this.account_uuid(),
			name: this.name(),
			refnr: this.refnr(),
			date_inserted: this.date_inserted(),
			fields: {
				billing_email: this.billing_email(),
				billing_address: this.billing_address(),
				shipping_address: this.shipping_address()
			},
			owner: this.owner().serialize(),
			seller: this.seller().serialize()
		};
	}

	async load()
	{
		let result = await Grape.fetches.getJSON(`api/account/`, {
			account_uuid: this.account_uuid
		});

		await this.set(result.account);

		return result;
	}

	async set(obj)
	{
		this.account_uuid(obj?.account_uuid);
		this.name(obj?.name);
		this.refnr(obj?.refnr);
		this.date_inserted(obj?.date_inserted);
		this.billing_email(obj?.fields?.billing_email);
		this.billing_address(obj?.fields?.billing_address);
		this.shipping_address(obj?.fields?.shipping_address);
		if (obj.hasOwnProperty('owner'))
			this.owner(new Contact(obj.owner));
		if (obj.hasOwnProperty('seller'))
			this.seller(new Seller(obj.seller));
	}
}

class Product {
	constructor(obj){
		this.product_uuid = ko.observable();
		this.name = ko.observable();
		this.sku = ko.observable();
		this.fields = ko.observable();
		this.aliases = ko.observable();
		this.categories = ko.observable();
		this.product_grouping = ko.observable();
		if (obj)
			this.set(obj);
	}

	serialize(){
		return ko.toJS(this);
	}

	set(obj){
		for (let field of ["product_uuid", "name", "sku", "fields", "aliases", "categories", "product_grouping"]){
			if (obj[field])
				this[field](obj[field]);
		}
	}

}

class PriceChangeRow
{
	constructor(obj)
	{
		//[{name: 'VAT', type: 'tax', amount:10.0, percentage:15, note: ''}]
		this.name = ko.observable();
		this.type = ko.observable();
		this.amount = ko.observable(0);
		this.percentage = ko.observable(0);
		this.value = ko.observable(0);
		this.note = ko.observable();
		this.value.subscribe((nv) => this.amountOrPercentage(nv) );

		if (obj)
			this.set(obj);
	}


	amountOrPercentage (value)
	{
		if (!value)
			return;

		if (value.includes('%'))
		{
			const match = value.match(/([-]?\d+(\.\d+)?)%/);
			if (match)
			{
				this.amount(undefined);
				this.percentage(parseFloat(match[1]));
			}
		}
		else
		{
			this.percentage(undefined);
			this.amount(parseFloat(value));
		}
	}

	clear ()
	{
		this.name(undefined);
		this.type(undefined);
		this.amount(undefined);
		this.value(undefined);
		this.note(undefined);
		this.percentage(undefined);
	}

	set (obj)
	{
		if (obj)
		{
			this.name(obj.name);
			this.type(obj.type);
			this.value(obj.value);
			if (obj.value && !obj.percentage && !obj.amount)
			{
				this.value(obj.value);
				this.amountOrPercentage(obj.value);
			}
			else
			{
				this.amount(obj.amount);
				this.percentage(obj.percentage);
			}

			this.note(obj.note || null)
		}
	}

	serialize()
	{
		return ko.mapping.toJS(this);
	}
}

class DocumentLine
{
	constructor(obj)
	{
		this.document_line_id = ko.observable();
		this.idx = ko.observable();
		this.qty = ko.observable(1);
		this.ppu = ko.observable(0);
		this.total_value = ko.observable(0);
		this.total_changes = ko.observable(0);
		this.total_payable = ko.observable(0);
		this.grouping = ko.observable();
		this.description = ko.observable();
		this.note = ko.observable();
		this.new_change = ko.observable(new PriceChangeRow());
		this.price_change_rows = ko.observableArray();
		this.changes_visible = ko.observable(false);
		this.note_visible = ko.observable(false);
		this.image = ko.observable();
		this.product = ko.observable();
		this.product.subscribe((newValue)=>{
			for (let field of [
				"description", "ppu",
			]){
				if (newValue[field])
					this[field](newValue[field]);
			}
		});

		this.price_change_rows.subscribe((newValue)=>{
			let total = 0;
			for (let c of newValue){
				if (c.percentage()){
					c.amount((this.total_value()+total)*(c.percentage()*0.01));
					total += c.amount();
				}
				else if (c.amount()){
					total += c.amount();
				}
			}
			this.total_changes(total);
		});

		this.total_changes.subscribe((newValue)=>{
			this.total_value.valueHasMutated();
		})

		this.total_value.subscribe((newValue) => {
			if (this.qty() != undefined && this.qty() != 0 && this.ppu() != undefined && (this.ppu()*this.qty() !== newValue))
				this.ppu(newValue/this.qty());

			if (this.qty() == 0)
				setTimeout(()=>{this.total_value(0)}, 1);

			this.total_payable(newValue + this.total_changes());
		});

		this.ppu.subscribe((newValue) => {
			if (this.qty() != undefined && (newValue*this.qty() !== this.total_value()))
				this.total_value(newValue*this.qty());
		});

		this.qty.subscribe((newValue) => {
			if (this.ppu() != undefined && newValue*this.ppu() !== this.total_value())
				this.total_value(newValue*this.ppu());
		});

		this.note.subscribe((newValue)=>{
			if (newValue) this.note_visible(true);
		});

		if (obj)
			this.set(obj);
	}

	set(obj)
	{
		this.document_line_id(obj.document_line_id);
		this.idx(obj.idx);
		this.qty(obj.qty);
		this.ppu(obj.ppu);
		this.total_value(obj.total_value);
		this.total_changes(obj.total_changes);
		this.total_payable(obj.total_payable);
		this.grouping(obj.grouping);
		this.description(obj.description);
		this.note(obj.note);
		this.product(obj.product || obj);
		this.image(obj.image);

		if (obj.hasOwnProperty('price_change_rows') && obj.price_change_rows)
		{
			for (let row of obj.price_change_rows)
				this.addPriceChangeRow(new PriceChangeRow(row));
		}
	}

	serialize()
	{
		return ko.mapping.toJS(this);
	}

	addPriceChangeRow (changeRow)
	{
		let changeRowObj = ko.mapping.toJS(changeRow);
		let priceChangeRow = new PriceChangeRow(changeRowObj);
		priceChangeRow.value.subscribe((newValue)=>{
			this.price_change_rows.valueHasMutated();
		})
		this.price_change_rows.push(priceChangeRow);
	}
}

class Document
{
	constructor(obj)
	{
		this.document_id = ko.observable();
		this.contact = ko.observable(new Contact());
		this.account = ko.observable(new Account());
		this.email_config = ko.observable(new EmailConfig());
		this.invoice_pdf = ko.observable(new InvoicePDF());

		this.document_nr = ko.observable();
		this.issue_date = ko.observable(moment(new Date()).format('YYYY/MM/DD'));
		this.due_date = ko.observable((moment(new Date()).add(1, 'M')).format('YYYY/MM/DD'));
		this.document_uuid = ko.observable();
		this.document_type = ko.observable();
		this.document_type_status = ko.observable();
		this.total_value = ko.observable(0);
		this.total_changes = ko.observable(0);
		this.total_payable = ko.observable(0);
		this.price_change_rows = ko.observableArray();
		this.new_change = ko.observable(new PriceChangeRow());
		this.document_options = ko.observable();
		this.voided = ko.observable();
		this.locked = ko.observable();

		this.lines = ko.observableArray([]);
		this.removed_lines = ko.observableArray([]);
		this.showSendParams = ko.observable(false);
		this.disable_send = ko.observable(false);

		this.lines.subscribe(()=>{
			this.price_change_rows.valueHasMutated();
		})

		this.total_value.subscribe((newValue)=>{
			this.price_change_rows.valueHasMutated();
		});

		this.price_change_rows.subscribe((newValue)=>{
			let total = 0;
			for (let c of newValue){
				if (c.percentage()){
					c.amount((this.total_value()+total)*(c.percentage()*0.01));
					total += c.amount();
				}
				else if (c.amount()){
					total += c.amount();
				}
			}
			this.total_changes(total);
			this.total_payable(this.total_value()+total);
		});

		if (obj)
			this.set(obj);
	}

	append_line(line)
	{
		line.total_payable.subscribe((newValue)=>{
			let total = 0;
			for (let l of this.lines()){
				total += l.total_payable();
			}
			this.total_value(total);
		});
		let lines = this.lines();
		let max_idx = 0;
		lines.map((x) => max_idx = max_idx < x.idx() ? x.idx() : max_idx);
		if (!max_idx)
			max_idx = 0;
		line.idx(max_idx+1);
		lines.push(line);
		this.lines(lines);
	}

	remove_line(line)
	{
		this.removed_lines.push(line);
		this.lines.pop(line)
	}

	async load_from_id(document_id)
	{
		this.document_id(document_id);
		const result = await Grape.fetches.getJSON('/api/accounts/document', {document_id: document_id});
		this.set(result);
	}

	serialize()
	{
		const lines = [];
		const rows = [];
		const removed_lines = [];
		for (let line of this.lines())
			lines.push(line.serialize());
		for (let row of this.price_change_rows())
			rows.push(row.serialize());
		const obj = {
			document_id: this.document_id() || null,
			document_nr: this.document_nr(),
			document_type: this.document_type(),
			document_type_status: this.document_type_status(),
			issue_date: this.issue_date(),
			account: this.account().serialize(),
			options: this.document_options(),
			price_change_rows: rows,
			lines: lines
		};
		if (this.removed_lines().length > 0)
		{
			for (let line of this.removed_lines())
				removed_lines.push(line.serialize());
			obj.removed_lines = removed_lines
		}

		return obj;
	}

	async set(obj)
	{
		this.email_config().load();

		if (obj.hasOwnProperty('document_id'))
			this.document_id (obj.document_id);
		if (obj.hasOwnProperty('document_nr'))
			this.document_nr (obj.document_nr);
		if (obj.hasOwnProperty('document_type'))
			this.document_type (obj.document_type);
		if (obj.hasOwnProperty('total_value'))
			this.total_value (obj.total_value);
		if (obj.hasOwnProperty('total_payable'))
			this.total_payable (obj.total_payable);
		if (obj.hasOwnProperty('lines'))
		{
			this.lines([]);

			for (let line of obj.lines)
				this.append_line(new DocumentLine(line));
		}
		if (obj.hasOwnProperty('lines'))
		{

			this.price_change_rows([]);
			for (let row of obj.price_change_rows)
				this.addPriceChangeRow(new PriceChangeRow(row));
		}
		if (obj.hasOwnProperty('account'))
			this.account(new Account(obj.account));
	}

	async save()
	{
		let obj = this.serialize();
		let result = await Grape.fetches.postJSON('/api/accounts/document', obj);

		await this.load_from_id(result.document_id);
	}

	async send()
	{
		this.disable_send(true);
		let message_uuid = this.email_config().template_name().message_uuid;
		let invoice = await this.invoice_pdf().create(this.document_id(), message_uuid);
		if (invoice.status == 'OK')
		{
			let to_address_list = this.email_config().to_address().replace(/\s+/g, '');
			let obj = {
				document_id: this.document_id(),
				config_data: {
					to_address: to_address_list,
					template_name: this.email_config().template_name().name,
					attachment: invoice.data
				}
			};

			let result = await Grape.fetches.postJSON('/api/accounts/document/send', obj);
			if (result.status === 'OK')
			{
				Grape.alerts.alert({
					type: 'success',
					title: 'Invoice Send',
					message: 'Invoice have been sent successfully'
				});
			}
			this.disable_send(false);
		}
		else
		{
			Grape.alerts.alert({
				type: 'warning',
				title: 'Unknown error',
				message: 'We have encountered an unknown error. Please contact IT support to look into this matter. [3]'
			});
		}
	}

	addPriceChangeRow (newPriceChangeRow)
	{
		newPriceChangeRow.value.subscribe(()=>{
			let total = 0;
			for (let c of this.price_change_rows()){
				total += c.value();
			}
			this.total_changes(total);
		})
		this.price_change_rows.push(newPriceChangeRow);
		this.new_change(new PriceChangeRow());
	}
}

class EmailConfig
{
	constructor()
	{
		this.templates = ko.observableArray();
		this.to_address = ko.observable();
		this.template_name = ko.observable();
	}

	async load ()
	{
		let template = {
			schema: 'messages',
			table: 'v_templates',
			fields: ['name', 'message_uuid'],
			filter: [
				{field: 'namespace', operand: '=', value: 'Invoices'}
			]
		};

		let result = await Grape.fetches.getJSON('/api/record', template);
		this.templates(result.records);
	}
}

class InvoicePDF
{
	async create (document_id, message_uuid)
	{
		try 
		{
			let result;
			if (message_uuid != '')
				result = await Grape.fetches.postJSON('/api/document/pdf/create', { document_id: document_id, message_uuid: message_uuid });
			else if (document_id != '')
				result = await Grape.fetches.postJSON('/api/document/pdf/create', { document_id: document_id });

			if (result.status !== 'ERROR')
				return result;
			else
				throw new Error(error)
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Could not create PDF' });
			console.error(error);
		}
	}

	async download (document_id)
	{
		let check = await this.pdf_check(document_id);
		if (!check.data.result)
		{
			let result = await this.create(document_id);

			if (result.status !== 'ERROR')
				await this.download(document_id);
		}
		else
			window.open(`/api/document/pdf?document_id=${document_id}`, '_blank');
	}

	async pdf_check (document_id)
	{
		let result = await Grape.fetches.postJSON('/api/document/pdf/check', { document_id: document_id });

		return result;
	}
}

export default {
	Document,
	DocumentLine,
	Account,
	Contact,
	Seller,
	PriceChangeRow,
	EmailConfig,
	InvoicePDF
};
