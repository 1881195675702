import template from './detail.html';

class AccountDetailVM
{
	constructor (page)
	{
		this.page = page;
        this.selected_account = ko.observable({name: null,seller:{name: null},owner:{personal_detail:{name: null}}});
        this.account = ko.observable(this.page.bindings.account);
		this.clear = ko.observable(false);
	}

    async load ()
    {
		if (typeof this.account() != 'undefined')
		{
			this.selected_account(this.account());
			let account = await Grape.fetches.getJSON(`api/accounts/?account_uuid=${encodeURI(this.account().account_uuid)}`);
			this.selected_account({});
			this.selected_account(account);
		}
		else
		{
			this.clear(true);
		}
    }
}

class AccountDetailPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new AccountDetailVM(this);
	}

	async init ()
	{
        this.viewModel.load();
    }

	teardown ()
	{
	}
}

export default {
	route: '[/]ui/crm/account',
	page_class: AccountDetailPage,
	template: template
}