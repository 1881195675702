
import template from './invoice-list.html';

/**
 * Invoice Page view model
 */
class InvoicePageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;
		this.records = ko.observableArray([]);

		this.table_fields = ko.observableArray([
			{
				name: 'document_nr'
			},
			{
				name: 'date_inserted',
				formatter: 'moment',
				format: 'YYYY-MM-DD HH:mm'
			},
			{
				name: 'username'
			},
			{
				name: 'total_value',
				formatter: 'currency'
			},
			{
				name: 'total_changes',
				formatter: 'currency'
			},
			{
				name: 'total_payable',
				formatter: 'currency'
			},
			{
				name: 'issue_date',
				formatter: 'moment',
				format: 'YYYY-MM-DD HH:mm'
			},
			{
				name: 'seller'
			}
		]);

		// Internal list of fields with observables, built in init()
		this.fields = ko.observableArray();
		this.visible_fields = ko.observableArray([]);
		this.visible_fields.subscribe(() => { this.update(); });

		this.filters = ko.observableArray([]);
		this.filters.subscribe(() => this.update());

		this.sortfield = ko.observable();
		this.sortorder = ko.observable('DESC');

		this.page_number = ko.observable();
		this.total_pages = ko.observable();

		this.init();
	}

	init()
	{
		let options = {};
		if (window.localStorage.getItem('crm.accounts.invoice-list-options'))
			options = JSON.parse(window.localStorage.getItem('crm.accounts.invoice-list-options'));
		else
			options = {
				fields: ['document_nr']
			};

		const list = [];
		for (let field of this.table_fields())
		{
			if (!field.name)
				continue;
			const newfield = {
				name: field.name,
				visible: ko.observable(options.fields.indexOf(field.name) < 0 ? false : true)
			};
			if (field.formatter)
			{
				if (field.formatter == 'moment')
					newfield.formatter = (x) => { return moment(x).format(field.format); }
				else if (field.formatter == 'currency')
					newfield.formatter = (v) => { let x = v || 0; return (field.symbol || 'R') + x.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'); }
			}
			list.push(newfield);
		}
		this.fields(list);

		if (options.sortfield)
			this.sortfield(options.sortfield);
		if (options.sortorder)
			this.sortorder(options.sortorder);
		if (options.filter)
			this.filters(options.filter);
	}

	async update()
	{
		const options = this.serializeOptions();
		const result = await Grape.fetches.getJSON('/api/record', options);
		if (result.status == 'OK')
		{
			this.records(result.records);
			this.page_number(result.page_number);
			this.total_pages(result.total_pages);
		}
		else
			Grape.alerts.alert({type: 'error', message: result.message || 'Fail' });
	}

	btnEdit_click(invoice)
	{
		Grape.navigate('/ui/invoice-edit?document_id=' + invoice.document_id, {document_id: invoice.document_id});
	}

	btnAdd_click ()
	{
		Grape.navigate('/ui/invoice-edit');
	}

	btnSortField_click(field_name)
	{
		if (this.sortfield() == field_name)
		{
			if (this.sortorder() == 'ASC')
				this.sortorder('DESC');
			else
				this.sortorder('ASC');
		}
		else
		{
			this.sortfield(field_name);
			this.sortorder('ASC');
		}

		this.update();
	}

	serializeOptions()
	{
		const fields = ['document_id'];
		for (let f of this.fields())
			if (f.visible())
				fields.push(f.name);

		const filters = [];
		for (let f of this.filters())
			filters.push({field: f.field, operator: f.operator, value: f.value});

		const options = {
			schema: 'ar',
			table: 'v_documents',
			fields: fields,
			filter: filters
		};

		if (this.sortfield() && this.sortfield().length)
		{
			options.sortfield = this.sortfield();
			options.sortorder = this.sortorder();
		}

		window.localStorage.setItem('crm.accounts.invoice-list-options', JSON.stringify(options));

		return options;
	}
}

/**
 * Invoice page class
 */
class InvoicePageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new InvoicePageViewModel(this);
		this.name = 'InvoicePageClass';
	}

	async init() {
	}

	async updateData() {
		await this.viewModel.update();
	}

	async teardown() {
	}
}

export default {
	route: '[/]ui/invoices',
	page_class: InvoicePageClass,
	template: template
}
